import React from "react";
import Jobs from "../../components/jobs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const GermanyPage = () =>
  <Layout>
    <SEO
      keywords={[`${cms.keyword} jobs germany`, `${cms.keyword} jobs berlin`, `${cms.keyword} jobs munich`, `${cms.keyword} jobs cologne`, `${cms.keyword} jobs hamburg`, `${cms.keyword} developer jobs germany`, `${cms.keyword} developer jobs munich`, `${cms.keyword} developer jobs berlin`, `${cms.keyword} developer jobs cologne`, `${cms.keyword} developer jobs hamburg`, `remote ${cms.keyword} jobs germany`, `${cms.keyword} software engineering jobs germany`, `${cms.keyword} software engineering jobs berlin`, `${cms.keyword} software engineering jobs munich`, `${cms.keyword} software engineering jobs cologne`]}
      title={`${cms.keyword} Jobs in Germany`}
      description={`Looking for a ${cms.keyword} Developer job in Germany? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs. Find ${cms.keyword} jobs in Berlin, Munich, Cologne, Hamburg and other places in Germany.`}
    />
    <Jobs countryProp={["DE"]} />
  </Layout>

export default GermanyPage;